<template>
  <div class="list">
    <navbar :title="headline"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row pt-4 pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="filterBezeichnung"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("global.designation") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model.trim="filterKurzbezeichnung"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("global.shortdesignation") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <v-select
                          v-model.trim="filterNotenstrukturstatus"
                          :options="stati"
                          :reduce="(s) => s.id"
                          label="bezeichnung"
                          :placeholder="$t('global.status')"
                        >
                          <span slot="no-options">{{ $t("global.nostatusfound") }}</span>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-2 pb-2">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="filterCount"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("global.numberofrecords") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="' Notenstruktur'"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <router-link
                      class="btn btn-success"
                      :to="{ name: 'Neue Notenstruktur' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-primary">
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      Ausgabe
                    </button>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-primary">
                      <font-awesome-icon
                        icon="fa-solid fa-comments"
                        class="mr-2"
                      />
                      Kommunikation
                    </button>
                  </div>
                  <div class="mr-2">
                    <b-dropdown variant="primary">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-cog"
                          class="mr-2"
                        />
                        <em>Aktionen</em>
                      </template>
                      <b-dropdown-item>First Action</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="notenstrukturen"
                    :fields="fields"
                    :filter="filterBezeichnung"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />
                      Notenstruktur löschen
                    </h4>
                  </div>
                  <div class="modal-body">
                    Wollen sie die ausgewählten Notenstrukturen wirklich
                    löschen?
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import InfiniteLoading from "vue-infinite-loading";

import { insertUrlParam, removeUrlParam } from "@/utils/Url";

export default {
  name: "Notenstrukturliste",
  components: {
    Navbar,
    InfiniteLoading,

    HeadMenu,
    HeadMenuFilter,
    //DatePicker,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Notenstruktur";
      },
    };
  },
  mixins: [page],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      page: 0,
      notenstrukturen: [],
      anzahlProPage: 50,
      filterBezeichnung: this.$route.query.filterBezeichnung,
      filterKurzbezeichnung: this.$route.query.filterKurzbezeichnung,
      filterNotenstrukturstatus: "174E30E69C8",
      filterCount: null,

      stati: [],
      kategorie: this.kategorieProp,
      infiniteId: +new Date(),
      sortBy: "kuerzel",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        { key: "kuerzel", sortable: true, label: this.$t("global.shortdesignation") },
        { key: "bezeichnung", sortable: true, label: "Bezeichnung" },
        { key: "parentStruktur", sortable: true, label: "Pfad" },
        { key: "gueltig_ab", sortable: true, label: "Gültig ab" },
        { key: "status", sortable: true, label: this.$t("global.status") },
        { key: "gewichtung", sortable: true, label: "Gewichtung" },
        { key: "rundung", sortable: true, label: "Rundung" },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.filterBezeichnung)
        params.filterBezeichnung = this.filterBezeichnung;
      if (this.filterKurzbezeichnung)
        params.filterKurzbezeichnung = this.filterKurzbezeichnung;
      if (this.filterNotenstrukturstatus)
        params.filterNotenstrukturstatus = this.filterNotenstrukturstatus;

      if (this.kategorie) params.kategorie = this.kategorie;
      if (this.filterCount != "") params.filterCount = this.filterCount;

      return params;
    },
    kategorien: {
      get() {
        return this.$store.state.bildungskategorien;
      },
      /*set(val) {
        this.$store.commit(SET_BILDUNGSKATEGORIEN, val);
      },*/
    },
    kategorieObj() {
      if (this.kategorie && this.kategorien)
        return this.kategorien.find((r) => r.id === this.kategorie);
      return null;
    },
    headline() {
      if (this.kategorieProp && this.kategorien) {
        let kategorie = this.kategorien.find(
          (r) => r.id === this.kategorieProp
        );
        if (kategorie) return kategorie.mehrzahl;
      }
      //return "Geschäftspartner";
      return "Notenstruktur";
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },
    filterNotenstrukturstatus: function () {
      this.resetLoading(this);
    },
    filterKurzbezeichnung: function () {
      this.resetLoading(this);
    },
    filterBezeichnung: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    },
  },
  created() {
    Api.get("notenstrukturstatus/").then(
      (response) => (this.stati = response.data)
    );
  },
  mounted() {},
  methods: {
    details(notenstrukturen) {
      this.$router.push({
        path: "/bildung/notenstruktur/" + notenstrukturen.id,
      });
    },
    resetLoading: debounce((self) => {
      self.page = 0;
      self.notenstrukturen = [];
      self.infiniteId += 1;
    }, 500),
    loeschen() {
      Api.delete("notenstruktur/", { data: this.selectedIds }).then(
        (response) => {
          this.schliesseLoeschenModal();

          this.notenstrukturen = this.notenstrukturen.filter(
            (v) => !response.data.success.includes(v.id)
          );
        }
      );
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    infiniteHandler($state) {
      Api.get("notenstruktur/", {
        params: this.queryParams,
      }).then((response) => {
        response.data.forEach((v) => {
          v.parentStruktur = v.kuerzel;
          if (v.parent_notenstruktur) {
            v.parentStruktur =
              v.parentStruktur + " / " + v.parent_notenstruktur.kuerzel;
            if (v.parent_notenstruktur.parent_notenstruktur) {
              v.parentStruktur =
                v.parentStruktur +
                " / " +
                v.parent_notenstruktur.parent_notenstruktur.kuerzel;
            }
          }
        });
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.notenstrukturen.push(...response.data);
          $state.loaded();
        } else {
          this.notenstrukturen.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style>
</style>
